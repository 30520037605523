import ErrorPage from '../ErrorPage';
import { myPaymentsAndInvoicesRoute } from '../../my-account/payment-and-invoices/route'
import { myRenewRoute } from '../../my-account/renew/route';
import { useNcassAuth0 } from '../../../../hooks/useNcassAuth0';
import ErrorPageContactUs from '../PageContactUsError';
import { wordpressContactUsRoute } from '../../external-routes';
import ApiResource from '../../../../components/ApiResource';
import DefaultRenderError from '../../../../components/dynamics';
import { MemberDetailsInfo } from '../../../../components/my-account/types';
import { PaymentTypes } from '../../../../components/my-account/member-payments/types';

const MembershipStatus = (): JSX.Element => {
    const { user } = useNcassAuth0();

    if (user.ncassAccountStatus === 'Archived') { return Renew('archived') }

    if (user.ncassAccountStatus === 'Cancelled') { return Renew('cancelled') }

    if (user.ncassAccountStatus === 'Expired') { return Renew('expired') }

    if (user.ncassAccountStatus === 'Suspended') { return Suspended('suspended') }

    return ErrorPageContactUs();
};

const Renew = (title: string) => {    
    const { user } = useNcassAuth0();
    return  <ApiResource resource={`/members/${user.membershipNumber}/details`} renderError={DefaultRenderError}>
                {(memberInfo: MemberDetailsInfo) => {
                    if (memberInfo.latestMembership.paymentType.name === PaymentTypes.CardPayment 
                        && user["http://ncass.org.uk/memberType"] === 'Caterer' 
                        && memberInfo.balance === 0) {
                        return <ErrorPage
                            title={`Membership ${title}.`}
                            heading={`Oops! Your account is currently ${title}.`}
                            description='Please click below to view your renewal options and to regain full access to your dashboard.'
                            buttonConfiguration={{ path: myRenewRoute.path, text: 'Renew Membership' }} />
                    } else {
                        return ContactDescriptionRestartMembership(title);
                    }

                }}
            </ApiResource>
}
const ContactDescriptionRestartMembership = (title: string) => {
    return <ErrorPage
        title={`Membership ${title}.`}
        heading={`Oops! Your account is currently ${title}.`}
        description='Click the link below to contact us and restart your membership '
        buttonConfiguration={{ path: wordpressContactUsRoute.url, text: 'Contact Us' }} />
}

const Suspended = (title: string) => {
    return <ErrorPage
        title={`Membership ${title}.`}
        heading="Oops! You don't currently have permission to view this page."
        description='This may be because your membership is suspended due to an overdue payment.'
        buttonConfiguration={{ path: myPaymentsAndInvoicesRoute.path, text: 'Go to Payments Page' }} />
}

export default MembershipStatus;

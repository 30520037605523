export type Transaction = {
    id: string,
    amount: number;
    amountString: string;
    dueDate?: Date;
    status: string;
    receiptDate?: Date;
    cardLastFourDigits?: string;
    cardExpiry?: Date;
    paymentMethod?: string;
    isOverdue? : boolean

}

export type Invoice = {
    id: string;
    invoiceNumber: string;
    type: string;
    name: string;
    description?: any;
    amount: number;
    tax: number;
    paid: number;
    outstanding: number;
    overdue: number;
    discountAmount: number;
    date: Date;
    dateConvertToString: string;
    transactions: Transaction[];
    overdueTransactionIds: string[];
}

export type TransactionInvoice = {
    membershipNumber: string;
    invoices: Invoice[];
}

export enum TransactionStatus {
    Due = "Due",
    Future = "Future",
    Successful = "Successful",
    Raised = "Raised",
    Unsuccessful = "Unsuccessful",
    Inactive = "Inactive",
    Cancelled = "Cancelled",
    NotCompleted = "Not Completed",
    FailedRetry = "Failed - Retry",
    FailedUnpaid = "Failed - Unpaid",
    FailedPaid = "Failed - Paid"
}

export enum PaymentTypes {
    Aap = "AAP",
    CardPayment = "Card Payment",
    CcaMonthly = "CCA Monthly",
    CpaMonthly = "CPA Monthly",
    ReactivateCPA = "Reactivate CPA"
}
import { WretchError } from 'wretch/types';
import { ErrorRenderer } from '../ApiResource/types';

export type RenderErrorProps = {
    error: WretchError,
    defaultRenderer: (error: WretchError) => JSX.Element,
}

const DefaultRenderError: ErrorRenderer = (error, defaultRenderer, reloadData) => {
    return error.status === 404
        ? null
        : defaultRenderer(error)
}

export default DefaultRenderError